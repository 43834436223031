import { useState } from "react";
import { Button,Col,FormGroup,Label } from "reactstrap";
import { useDispatch } from "react-redux";
import { Formik, Field, Form,ErrorMessage } from "formik";
import { validateContactForm } from "../utils/validateContactForm";
import { postMessage, recaptchaAuth } from "./contactSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const ContactForm = () => {

    const[ contractor, setContractor] = useState("");
    const [ proofOfFunds, setProofOfFunds] = useState("");
  
    const handleRadioChange = (value) => { 
        setContractor(value); 
    };

    const handleRadioChange2 = (value) => { 
        setProofOfFunds(value); 
    };

    const dispatch = useDispatch();
    const recaptchaRef = useRef(null);
    //const recaptchaRef = React.createRef();

    const notifySuccess = () => {
        toast("Message sent successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
            className: 'toast-message'
        });
    };
    const notifyError = () => {
        toast("Something went wrong!", {
            position: toast.POSITION.BOTTOM_CENTER,
            className: 'toast-message'
        });
    };
    toast.configure();

    const getRecaptchaScore = () => {
        const inputVal = ContactForm.email;
        const token = recaptchaRef.current.getValue();
        console.log("token = "+token);
        dispatch(recaptchaAuth({inputVal: inputVal, token: token}));
    };

    const handleSubmit = (values,{resetForm}) => {
        console.log('form values:', values);
        console.log('in JSON format:', JSON.stringify(values));

        const message = {
            name: values.name,
            email: values.email,
            phoneNum: values.phoneNum,
            investor: values.investor,
            proofOfFunds : proofOfFunds,
            hardMoney: values.hardMoney,
            contractor : contractor,
            buyBox : values.buyBox,
            type: "OFFMARKET_PROPERTIES"
        }

        dispatch(getRecaptchaScore);
        dispatch(postMessage(message));
        resetForm();
        handleRadioChange('');
        handleRadioChange2('');
        recaptchaRef.current.reset();     
    };

    return(
        <Formik
        initialValues={{
            name: '',
            email: '',
            phoneNum: '',
            investor: '',
            proofOfFunds: '',
            hardMoney: '', 
            contractor: '',
            buyBox: '',       
        }}
        onSubmit ={handleSubmit}
        validate ={validateContactForm}
        >
            <Form style={{fontFamily: 'Diphylleia'}}>
            <FormGroup row>
                    <Label className="required" htmlFor="name">Name </Label>
                    <Col md='6'>
                        <Field 
                            name ='name' 
                            className ='form-control'/>
                        <ErrorMessage name = 'name'>
                            {(msg) => <p className="errorMessage">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                   <Label className="required" htmlFor="email">Email</Label>
                    <Col md='6'>
                        <Field 
                            isrequired = 'true'
                            name ='email'
                            type ='email'
                            className ='form-control'/>
                        <ErrorMessage name = 'email'>
                            {(msg) => <p className="errorMessage">{msg}</p>}
                        </ErrorMessage>  
                    </Col> 
                </FormGroup>
                <FormGroup row>
                <Label className="required" htmlFor="phoneNum">Phone</Label>
                    <Col md='6'>
                        <Field 
                            isrequired = 'true'
                            name ='phoneNum'
                            className ='form-control'/>
                        <ErrorMessage name = 'phoneNum'>
                            {(msg) => <p className="errorMessage">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label className="required" htmlFor="investor">Are you an Investor, Wholesaler, Flipper or Lender?</Label>
                    <Col md='6'>
                        <Field 
                            name ='investor' 
                            className ='form-control'/>
                        <ErrorMessage name = 'investor'>
                            {(msg) => <p className="errorMessage">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={{size:12}} className="text-black required" >
                    Do You Have A Proof Of Funds?  {' '}{' '}{' '}
                        <Field
                            name='proofOfFunds'
                            type ='radio'
                            value = 'Yes'
                            checked={proofOfFunds === "Yes"}
                            onChange={() => handleRadioChange2( "Yes")}
                            className ='form-radio-input'
                            /> {' '}
                        Yes {' '}
                        <Field
                            name='proofOfFunds'
                            type ='radio'
                            value = 'No'
                            checked={proofOfFunds === "No"}
                            onChange={() => handleRadioChange2( "No")}
                            className ='form-radio-input'
                            /> {' '}
                        No    
                    </Col>
                </FormGroup>
                <FormGroup row>
                <Label className="required" htmlFor="hardMoney">Do You Need A Private Or Hard Money Lender?</Label>
                    <Col md='6'>
                        <Field 
                            isrequired = 'true'
                            name ='hardMoney'
                            className ='form-control'/>
                        <ErrorMessage name = 'hardMoney'>
                            {(msg) => <p className="errorMessage">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={{size:12}} className="text-black required" >
                    Do You Need A Contractor? {' '}{' '}
                        <Field
                        name='contractor'
                        type ='radio'
                        value = 'Yes'
                        checked={contractor === "Yes"}
                        onChange={() => handleRadioChange( "Yes")}
                        className ='form-radio-input'
                        /> {' '}
                    Yes {' '}
                    <Field
                        name='contractor'
                        type ='radio'
                        value = 'No'
                        checked={contractor === "No"}
                        onChange={() => handleRadioChange( "No")}
                        className ='form-radio-input'
                        /> {' '}
                    No
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label className="required" htmlFor="buyBox">What Is Your Buy Box?</Label>
                    <Col md='6'>
                        <Field
                            isrequired = 'true'
                            name ='buyBox'
                            as= 'textarea'
                            rows='5'
                            className ='form-control'/>
                        <ErrorMessage name = 'buyBox'>
                            {(msg) => <p className="errorMessage">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                
                <FormGroup row>
                    <Col md ={{size:5}}>
                        <ReCAPTCHA 
                            sitekey={process.env.REACT_APP_SITE_KEY} 
                            ref={recaptchaRef}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col  className= "text-center" md ={{size:10, offset:1}}>
                        <Button  onClick={Object.values(validateContactForm).length === 0 ? notifySuccess : notifyError} type= 'submit' className="shadow-5-strong"style={{fontFamily: 'Diphylleia', fontSize: 'small', color: '#a7a9ac',background: '#552448', boxShadow: '1px 2px 9px #000001'}}>
                            Submit
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
        </Formik>
    );
};

export default ContactForm;