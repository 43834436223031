import { Container, Row} from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactForm from "./ContactForm";
import { Link } from "react-router-dom";

const HeroContent = () => {
    return(
            <Container className="row-content" style={{background: '#ECE3E5' }}>
                <Row className="row-content" style={{marginTop: '3%'}}>
                    <h1 className='text-center' style={{font: 'Diphylleia', fontSize: '1em'}}>Las Vegas Off Market Properties is the premier destination for real estate wholesalers, flippers, investors, and lenders seeking off-market properties in Las Vegas. We specialize in connecting buyers and sellers with exclusive deals on distressed properties, foreclosures, tax-delinquent homes, underwater properties, short sale approved homes, and homes needing TLC. Whether you're looking to invest in fixer uppers, handyman specials, damaged homes, or probate properties, our platform offers incredible opportunities.<br /><br />With a growing network of real estate professionals, we help you find the best flipping and investment properties in Las Vegas, from homes that are back on the market to those with motivated sellers and reduced prices. Whether you're a seasoned investor or a new flipper, Las Vegas Off-Market Properties provides comprehensive property listings and connections to hard money lenders and wholesale real estate deals.<br /><br />Browse our Las Vegas distressed properties or tax-delinquent homes today, and take advantage of our exclusive off-market opportunities. Find wholesale homes, real estate deals, and flip-ready properties in Las Vegas, and maximize your investment returns.<br /><br />For more in-depth analysis of the local market, visit <Link to= 'https://lasvegashousingmarketreport.com/' style={{ color: '#670038', fontWeight: 'bold' }}> Las Vegas Housing Market Report</Link></h1>
                    <Row >
                        <h2 className= "text-center" style={{font: 'Diphylleia', color: '#552448', fontSize: '1.7em', marginTop: '2%'}} >Real Estate Investor</h2>
                        <hr/>
                    </Row>
                    <Row>
                        <p className="errorMessage" style={{fontStyle: "italic"}}>* Indicates required field</p>
                    </Row>
                    <Row>
                        <ContactForm/>
                    </Row>
                </Row>
            </Container>          
    );
};


export default HeroContent;