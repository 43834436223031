export const validateContactForm = (values) => {
    const errors = {};
    
    if(!values.name) {
        errors.name = 'Required';
    }else if(values.name.length < 2) {
        errors.name = 'Must be at least 2 characters.';
    } else if(values.name.length > 40){
        errors.name = 'Must be 40 characters or less.';
    }

    if(!values.email.includes('@')) {
        errors.email = 'Please use a valid email address.';
    } 

    if(!values.investor) {
        errors.investor = 'Required';
    }else if(values.investor.length < 6) {
        errors.investor = 'Must be at least 6 characters.';
    } else if(values.investor.length > 40){
        errors.investor = 'Must be 40 characters or less.';
    }

    if(!values.hardMoney) {
        errors.hardMoney = 'Required';
    }else if(values.hardMoney.length < 1) {
        errors.hardMoney = 'Must be at least 6 characters.';
    } else if(values.hardMoney.length > 40){
        errors.hardMoney = 'Must be 40 characters or less.';
    }

    if(!values.buyBox) {
        errors.buyBox = 'Required';
    }else if(values.buyBox.length < 3) {
        errors.buyBox = 'Must be at least 3 characters.';
    } else if(values.buyBox.length > 1000){
        errors.buyBox = 'Must be 1000 characters or less.';
    }

    const reg = /^\d+$/;
    if(!reg.test(values.phoneNum)) {
        errors.phoneNum = 'The phone number should contain only numbers.';
    }
  
    console.log(Object.values(validateContactForm).length);
    return errors;
};